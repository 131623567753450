import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';
import { ConfigService } from 'src/app/config/config.service';


@Injectable({
  providedIn: 'root'
})
export class HttpService {
  token:any=null
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
  ) {
    
    // let authorizationToken = this.storageDataService.getAuthorizationToken('userData');
    // if (!!authorizationToken) {
    //   this.token=authorizationToken
    // }
    
   }
   getHeader() {
		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
				Accept: 'application/json',
				'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE, PUT',
				'Access-Control-Allow-Origin': '*',
				'Access-Control-Allow-Credentials': 'true',
				'Access-Control-Allow-Headers':
					'Origin, X-Requested-With, Content-Type, Accept, Authorization',
				'Cache-Control': 'no-cache',
				Pragma: 'no-cache'
			}),
		};
		return httpOptions;
	}
  post(serviceName: string, data: any): Observable<any> {
    const options =this.getHeader()
    const url = this.configService.getServerBaseURL() + serviceName;
    return this.http.post<any>(url, data);
  }

  get(serviceName: string): Observable<any> {
     const options =this.getHeader()
    const url = this.configService.getServerBaseURL() + serviceName;
    return this.http.get<any>(url);
  }

  fileUpload(serviceName: string, data: any): Observable<any> {
    const options =this.getHeader()
    const url = this.configService.getServerBaseURL() + serviceName;
    return this.http.post<any>(url, data, {
      reportProgress: true,
      observe: 'events'
    });
  }


}
