import { Component, OnInit } from '@angular/core';
import { NavigationService } from '../../../../services/navigation.service';
import { SearchService } from '../../../../services/search.service';
import { AuthService } from '../../../../services/auth.service';
import { Router } from '@angular/router';
import { SharedService } from 'src/app/shared/services/shared.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header-sidebar-large',
  templateUrl: './header-sidebar-large.component.html',
  styleUrls: ['./header-sidebar-large.component.scss']
})
export class HeaderSidebarLargeComponent implements OnInit {
    notifications: any[];
    name: any='';
    pCash: any='0';
    cart: any=0;
    signInlayout:boolean=false
    public postsSubscription: Subscription;
    constructor(
      private navService: NavigationService,
      public searchService: SearchService,
      private auth: AuthService,
      private router:Router,
      private sharedService: SharedService
    ) {
     
          if(localStorage.getItem("demo_login_status")!='undefined' && localStorage.getItem("demo_login_status")!=null){
            this.signInlayout=true
          }else{
            this.signInlayout=false
          }
     // this.name=this.auth.getuser()
      // this.getPcash()
      // this.getCart()
    }
  
    ngOnInit() {
    //   this.postsSubscription =  this.sharedService.getPosts()
    // .subscribe(response => {
    // this.getCart()
    // this.getPcash()
    // })
    }
    ngOnDestroy() {
     // this.postsSubscription.unsubscribe();
    }
  
    toggelSidebar() {
      const state = this.navService.sidebarState;
      if (state.childnavOpen && state.sidenavOpen) {
        return state.childnavOpen = false;
      }
      if (!state.childnavOpen && state.sidenavOpen) {
        return state.sidenavOpen = false;
      }
      // item has child items
      if (!state.sidenavOpen && !state.childnavOpen 
        && this.navService.selectedItem.type === 'dropDown') {
          state.sidenavOpen = true;
          setTimeout(() => {
              state.childnavOpen = true;
          }, 50);
      }
      // item has no child items
      if (!state.sidenavOpen && !state.childnavOpen) {
        state.sidenavOpen = true;
      }
    }
  
    signout() {
      localStorage.removeItem("demo_login_status");
      localStorage.removeItem('product')
      this.router.navigateByUrl("/sessions/signin");
    }
    getCart(){
      // this.auth.getCart('carts/count').subscribe((res)=>{
      //   if(res.status && res.data!=null){
      //     this.cart=JSON.parse(res.data);
      //   }else{
      //     this.cart=0;
      //   }
      // })
      this.cart=0;
    }
    getPcash(){
      // this.auth.getPcash('user/getpcash').subscribe((res)=>{
      //   if(res.status && res.data!=null){
      //     this.pCash=JSON.parse(res.data.pcash);
      //     this.name=res.data.name;
      //   }else{
      //     this.cart=0;
      //   }
      // })
      this.cart=0;
    }
    

}
