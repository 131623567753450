import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[write-product-review]',
  templateUrl: './write-product-review.component.html',
  styleUrls: ['./write-product-review.component.scss']
})
export class WriteProductReviewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
