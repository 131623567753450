import { Injectable } from '@angular/core';

@Injectable()
export class PaymentService {
  initiatePayment(orderId: string, amount: string) {
    const data = {
      MID: 'DzUfVr33211772212898',
      WEBSITE: 'WEBSTAGING', // Use 'DEFAULT' in production
      INDUSTRY_TYPE_ID: 'Retail',
      CHANNEL_ID: 'WEB',
      TXN_AMOUNT: amount,
      ORDER_ID: orderId,
      CUST_ID: 'customer123',
      MOBILE_NO: '9999999999',
      EMAIL: 'customer@example.com',
      CHECKSUMHASH: ''
    };

    // Redirect to the Paytm gateway
    window.location.href = 'https://securegw-stage.paytm.in/theia/processTransaction?' + this.encodeFormData(data);
  }

  private encodeFormData(data: any): string {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  }
}
