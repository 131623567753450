import { Component, OnInit } from '@angular/core';

@Component({
  selector: '[cancellation-request]',
  templateUrl: './cancellation-request.component.html',
  styleUrls: ['./cancellation-request.component.scss']
})
export class CancellationRequestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
