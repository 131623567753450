import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface IMenuItem {
    id?: string;
    title?: string;
    description?: string;
    type: string;       // Possible values: link/dropDown/extLink
    name?: string;      // Used as display text for item and title for separator type
    state?: string;     // Router state
    icon?: string;      // Material icon name
    tooltip?: string;   // Tooltip text
    disabled?: boolean; // If true, item will not be appeared in sidenav.
    sub?: IChildItem[]; // Dropdown items
    badges?: IBadge[];
    active?: boolean;
}
export interface IChildItem {
    id?: string;
    parentId?: string;
    type?: string;
    name: string;       // Display text
    state?: string;     // Router state
    icon?: string;
    sub?: IChildItem[];
    active?: boolean;
}

interface IBadge {
    color: string;      // primary/accent/warn/hex color codes(#fff000)
    value: string;      // Display text
}

interface ISidebarState {
    sidenavOpen?: boolean;
    childnavOpen?: boolean;
}

@Injectable({
    providedIn: 'root'
})
export class NavigationService {
    public sidebarState: ISidebarState = {
        sidenavOpen: true,
        childnavOpen: false
    };
    selectedItem: IMenuItem;
    role=''
    constructor() {
       this.role =JSON.parse(localStorage.getItem("user_type"));
    }
    
    defaultMenu: IMenuItem[] = [
       
        {
            name: 'Dashboard',
            description: 'Dashboard',
            type: 'link',
            icon: 'i-Posterous',
            state: '/dashboard/v3'
        },
        {
            name: 'Pending orders',
            description: 'Pending orders',
            type: 'link',
            icon: 'i-Information',
            state: '/pages/pending-orders'
        }
        ,
        {
            name: 'Printed orders',
            description: 'Printed orders',
            type: 'link',
            icon: 'i-Letter-Open',
            state: '/pages/printed-orders'
        }
        ,
        {
            name: 'Packed orders',
            description: 'Packed orders',
            type: 'link',
            icon: 'i-Wallet',
            state: '/pages/packed-orders'
        }
        ,
        {
            name: 'Dispached orders',
            description: 'Dispached orders',
            type: 'link',
            icon: 'i-Mail-Send',
            state: '/pages/dispached-orders'
        }
        ,
        {
            name: 'All orders',
            description: 'All orders',
            type: 'link',
            icon: 'i-Eye',
            state: '/pages/all-orders'
        }
        ,
        {
            name: 'Users',
            description: 'Users orders',
            type: 'link',
            icon: 'i-Find-User',
            state: '/pages/users'
        },
        {
            name: 'Categories',
            description: 'Categories',
            type: 'link',
            icon: 'i-Network-Window',
            state: '/pages/categories'
        }
        ,
        {
            name: 'Products',
            description: 'Products',
            type: 'link',
            icon: 'i-Cloud-Sun',
            state: '/pages/products'
        }
        ,
        {
            name: 'Coupans',
            description: 'Coupans',
            type: 'link',
            icon: 'i-Bebo',
            state: '/pages/coupans'
        } ,
        {
            name: 'Cancel Orders',
            description: 'Cancel Orders',
            type: 'link',
            icon: 'i-Arrow-X-Left',
            state: '/pages/cancel-orders'
        } ,
        {
            name: 'Change Vendors',
            description: 'Change Vendors',
            type: 'link',
            icon: 'i-Left-To-Right',
            state: '/pages/change_vendors'
        },
        {
            name: 'Unpaid Carts',
            description: 'Unpaid Carts',
            type: 'link',
            icon: 'i-Arrow-Circle',
            state: '/pages/unpaid-orders'
        }
      
        
    ];
    vendorMenu: IMenuItem[] = [
       
        {
            name: 'Dashboard',
            description: 'Dashboard',
            type: 'link',
            icon: 'i-Posterous',
            state: '/dashboard/v3'
        },
        {
            name: 'Pending orders',
            description: 'Pending orders',
            type: 'link',
            icon: 'i-Information',
            state: '/pages/pending-orders'
        }
        ,
        {
            name: 'Printed orders',
            description: 'Printed orders',
            type: 'link',
            icon: 'i-Letter-Open',
            state: '/pages/printed-orders'
        }
        ,
        {
            name: 'Packed orders',
            description: 'Packed orders',
            type: 'link',
            icon: 'i-Wallet',
            state: '/pages/packed-orders'
        }
        ,
        {
            name: 'Dispached orders',
            description: 'Dispached orders',
            type: 'link',
            icon: 'i-Mail-Send',
            state: '/pages/dispached-orders'
        }
        ,
        {
            name: 'All orders',
            description: 'All orders',
            type: 'link',
            icon: 'i-Eye',
            state: '/pages/all-orders'
        }
       
      
        
    ];

    // sets iconMenu as default;
 
    menuItems = new BehaviorSubject<IMenuItem[]>(this.defaultMenu);
        menuItems$ = this.menuItems.asObservable();
    
    // navigation component has subscribed to this Observable
    

    // You can customize this method to supply different menu for
    // different user type.
    publishNavigationChange(menuType: string) {
        this.role =localStorage.getItem("user_type");
      switch (this.role) {
        case 'ADMIN':
          this.menuItems.next(this.defaultMenu);
          break;
        case 'VENDOR':
          this.menuItems.next(this.vendorMenu);
          break;
        default:
         // this.menuItems.next(this.defaultMenu);
      }
    }
}
