<div *ngIf="!signInlayout" class="auth-layout-wrap"
    style="background-image: url(./assets/images/printster-{{random_number}}.jpg)">
    <div class="auth-content">
        <router-outlet></router-outlet>
    </div>
</div> 

<div class="app-admin-wrap layout-sidebar-large" *ngIf="signInlayout">
    <div>
        <app-header-sidebar-large></app-header-sidebar-large>
    </div>
   
    <div class="main-content-wrap d-flex flex-column">
        <router-outlet></router-outlet>
        <div class="flex-grow-1"></div>
        <app-footer></app-footer>
       
    </div>
</div>

