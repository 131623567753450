import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { LocalStoreService } from '../shared/services/local-store.service';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor( private router: Router,private store: LocalStoreService) { 
   
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {

    if(this.store.getItem("demo_login_status")===true){
      //this.router.navigate(['dashboard/v3'])
      return true;
    } else {
      //this.router.navigate(['sessions/signin'])
      return false;
    }
  }

}
