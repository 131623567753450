import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from './shared/services/auth.service';
import { LocalStoreService } from './shared/services/local-store.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent   {
  title = 'bootDash';
  constructor(
    private auth: AuthService,
    private router: Router,
    private store: LocalStoreService,
    private route: ActivatedRoute ) { 
      this.route.queryParams.subscribe(params => {
        if(params['document']!=undefined && params['document']!=''){
         
          localStorage.setItem('product',params['document'])
        }
       
  
      });
    
    
}

 
}
