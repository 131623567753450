<div class="app-footer">

<!-- <div class="row">
<div class="col-md-6">Printster.in KNowledge Center</div>
<div class="col-md-6">Details of Products</div>

</div> -->


	<div class="footer-bottom border-top pt-3 d-flex flex-column flex-sm-row align-items-center">
		<a href="">Terms and Conditions</a>&nbsp;&nbsp; |&nbsp;&nbsp;
		<a href="">Refund & Cancellation Policy</a>

		<span class="flex-grow-1"></span>
		<div class="d-flex align-items-center">

			<div>
				<p class="m-0">&copy; 2023 Printster.in. All Rights Reserved.</p>

			</div>
		</div>
	</div>
</div>
