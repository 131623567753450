import { ElementRef } from '@angular/core'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
} from '@angular/common/http'
import { tap } from 'rxjs/operators'
import { Router } from '@angular/router'


@Injectable({
  providedIn: 'root',
})
export class TokenizeRequestService implements HttpInterceptor {
  constructor(
    private router: Router
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    
    let token = ''
    const requestHeader: any = {}
    
    if (window.localStorage.getItem('token') && window.localStorage.getItem('demo_login_status')) {
      token = window.localStorage.getItem('token')
      // requestHeader.setHeaders = {
      //   Authorization: token
      // }
      token=token.replace('"','');
      token=token.replace('"','');
      const newUrl = {url: req.url+'?token='+token};
      
      req = Object.assign(req, newUrl);
      
    }
    
    
    
    const tokenizeRequest = req.clone(requestHeader)


    return next.handle(tokenizeRequest).pipe(
      tap((evt) => {
        
        if (evt instanceof HttpResponse) {
         
          if (evt.body.status=== undefined) {
            return
          }

          if ((!evt.body.status && evt.body.message=='Invailid Credentials') || (!evt.body.status && evt.body.message=='Token Time Expire.') || (!evt.body.status && evt.body.message=='Token is not valid')) {
            window.localStorage.clear();
            console.log('session got expired! User logout')
            for (const item in localStorage) {
              if (item.includes(item)) {
                window.localStorage.removeItem(item)
              }
            }
            this.router.navigate(['sessions/signin'])
          }

         
        }

        
      }),
    )
  }

  
}
