import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  public posts = new Subject();
  constructor() { }
  setPost() {
   this.posts.next();
  }
  getPosts() {
    return this.posts;
  }
}