<div class="main-header border-bottom border-primary">

    <div class="logo text-center pl-2 pr-2 d-none d-md-block d-xl-block" style="width: 300px"  >
      <a href="" routerLink="/dashboard/v3" aria-details="Printster.in Logo">  <img src="./assets/images/logo.png" style="width: 300px"></a>

    </div>

    <div class="logo m-0 p-0 d-block d-sm-none"  style="width: 80px"  >
        <a href="" routerLink="/dashboard/v3">   <img src="./assets/images/logo-fit.png" style="width: 80px" ></a>

    </div>










<div class="header-part-right" *ngIf="signInlayout">
    <a  routerLink="pages/pcash" class="d-none d-xl-block">
        <div ngbDropdown [placement]="'bottom-right'" >
            <div class="badge-top-container">
           <i ngbDropdownToggle class="i-Money-2 text-muted header-icon" > </i> <span class="badge badge-primary">{{pCash}}</span>
        </div>
        </div>
    </a>

    <!-- <a  routerLink="dashboard/settings" class="d-none d-xl-block">
        <div ngbDropdown [placement]="'bottom-right'">
            <div class="badge-top-container" >
                <span class="badge badge-primary">{{cart}}</span>
                <i ngbDropdownToggle class="i-Full-Cart text-muted header-icon" ></i>
            </div>

        </div>
    </a> -->
        <div ngbDropdown [placement]="'bottom-right'" class=" user col align-self-end">
          <button ngbDropdownToggle> <i class="i-MaleFemale mr-1"></i>  {{name}} &nbsp;  &nbsp; </button>
            <!-- <img src="./assets/images/faces/7.png" id="userDropdown"  alt="{{name}}"> -->


            <div ngbDropdownMenu aria-labelledby="userDropdown" class="header-account-dropdown">

<!-- 
                <a class="dropdown-item" routerLink="/pages/profile"> <i class="i-MaleFemale mr-1"></i>My Profile</a>
                <a class="dropdown-item" routerLink="/pages/orders"><i class="i-Shopping-Bag mr-1"></i>My Orders</a>
                <a class="dropdown-item" routerLink="/pages/address"> <i class="i-Address-Book mr-1"></i> Addresses</a>
                <a class="dropdown-item" routerLink="/dashboard/settings"> <i class="i-Full-Cart mr-1"></i>My Cart &nbsp;<span class="badge badge-primary ">{{cart}}</span></a>
                <a class="dropdown-item" routerLink="/pages/pcash"><i class="i-Wallet mr-1"></i> P-Cash Wallet &nbsp; <span class="badge badge-primary ">{{pCash}}</span></a>
                <a class="dropdown-item" routerLink="/pages/orders"><i class="i-Password-shopping mr-1"></i> Change Password</a>-->
                <a class="dropdown-item" (click)="signout()"><i class="i-Close-Window mr-1"></i>  Logout</a> 
            </div>
        </div>

</div>
<div class="header-part-right" *ngIf="!signInlayout">
 

        <div ngbDropdown [placement]="'bottom-right'" class=" user col align-self-end">
          <button ngbDropdownToggle> <i class="i-MaleFemale mr-1"></i>  User &nbsp;  &nbsp; </button>
            <!-- <img src="./assets/images/faces/7.png" id="userDropdown"  alt="{{name}}"> -->


            <div ngbDropdownMenu aria-labelledby="userDropdown" class="header-account-dropdown">


                <a class="dropdown-item" routerLink="/"> <i class="i-MaleFemale mr-1"></i>Sign In</a>
 
            </div>
        </div>

</div>

</div>

