import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { ToastrModule } from 'ngx-toastr';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SearchModule } from './components/search/search.module';
import { SharedComponentsModule } from './components/shared-components.module';
import { SharedDirectivesModule } from './directives/shared-directives.module';
import { SharedPipesModule } from './pipes/shared-pipes.module';
import { AddOrderInstructionsComponent } from './add-order-instructions/add-order-instructions.component';
import { DownloadInvoiceComponent } from './download-invoice/download-invoice.component';
import { TrackOrderComponent } from './track-order/track-order.component';
import { CancellationRequestComponent } from './cancellation-request/cancellation-request.component';
import { WriteProductReviewComponent } from './write-product-review/write-product-review.component';

@NgModule({
  imports: [
    CommonModule,
    PerfectScrollbarModule,
    SearchModule,
    ToastrModule.forRoot(),
    NgbModule,
    SharedComponentsModule,
    SharedDirectivesModule,
    SharedPipesModule,
    RouterModule
  ],
  declarations: [
    AddOrderInstructionsComponent,
    DownloadInvoiceComponent,
    TrackOrderComponent,
    CancellationRequestComponent,
    WriteProductReviewComponent
  ],
  exports:[AddOrderInstructionsComponent,
    DownloadInvoiceComponent,
    TrackOrderComponent,
    CancellationRequestComponent,
    WriteProductReviewComponent]
})
export class SharedModule { }
