import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {
  routerEvents: any;
  random_number:number=0;
  signInlayout:boolean=false
  constructor(private router: Router) { 
    this.routerEvents = this.router.events.subscribe(
      (event:any)=>{
        if(event instanceof NavigationEnd){
          // console.log(event.url);
          // if(localStorage.getItem("demo_login_status")!='undefined' && localStorage.getItem("demo_login_status")!=null){
          //   this.signInlayout=true
          // }else{
          //   this.signInlayout=false
          // }
          if(event.url=='/sessions/signin' || event.url=='/sessions/signup' || event.url=='/sessions/forgot'){
            this.signInlayout=false
          }else{
            this.signInlayout=true
          }
          
        
        }
      }
    )
    

    this.getRandomInt();

  }

  ngOnInit() {
  }
  getRandomInt() {
    this.random_number= Math.floor(Math.random() * 9);
  }


}
